@import url(https://rsms.me/inter/inter.css);
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
html { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  html { font-family: 'Inter var', sans-serif; }
}
* {
  box-sizing: border-box;
}
body {
  font-size: 20px;
  font-weight: 300;
  background:#070707;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.75;
  letter-spacing: -0.1px;
}
.wrap {
  width: 100%;
}
a {
  display: inline;
  color: inherit;
  text-decoration: none;
  border-bottom: 1px solid;
}

.notice {
  background: #222;
  text-align: center;
  padding: .75em 1em;
  line-height: 1.25em;
}
.notice a {
  color: #aaa;
  border: none;
  font-weight: 500;
  font-size: 0.75em;
}

header {
  padding: 2.5em 1.5em 1.5em 1.5em;
  height: 28px;
}
header .logo {
  float: left;
  width: 28px;
  height: 28px;
  fill: #fff;
}
header .links {
  float: right;
}
header .links .link-item {
  display: inline-block;
  margin-left: 1.5em;
}
.intro {
  font-size: 1.5em;
  margin-bottom: 3em;
}
.intro a {
  display: inline-block;
  margin-top: 2.5em;
  font-size: 0.5em;
}

.page {
  padding: 3em 1.5em;
}

.work {
  width: 100%;
}

.work--item {
  display: block;
  margin-bottom: 5em;
  padding-top: 1.25em;
  border-top: 1px solid white;
}
.work--item-info {
  margin-bottom: 1em;
}
.work--item-year,
.work--item-role {
  display: inline-block;
  margin-right: 0.25em;
}
.work--item-role::after {
  content: ","
}

@media (min-width: 860px) {
  body {
    font-size: 24px;
  }
  header {
    padding: 3em;
    height: 38px;
  }
  header .logo {
    width: 38px;
    height: 38px;
  }
  .page {
    max-width: 1140px;
    padding: 4em 3em;
    margin: 0 auto;
  }
  .intro {
    font-size: 1.75em;
    margin-bottom: 4em;
  }
  .work--item {
    clear: both;
  }
  .work--item-info {
    float: left;
    width: 30%;
  }
  .work--item-role,
  .work--item-year {
    display: block;
  }
  .work--item-role::after {
    content: "";
  }
  .work--item-description {
    float: left;
    width: 70%;
    margin-bottom: 5em;
  }
}
